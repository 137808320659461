import React, { useCallback, useLayoutEffect } from 'react';
import { Account, useAuthLogic } from '../utils/SignInLogic';
import { useToast } from '@cardboard-ui/react';
import { t } from '@lingui/macro';
import { Capacitor } from '@capacitor/core';
import { globalizeAuthUrl } from '../../SignInWithTokenAccountSelect';
import { SigninPickPlatform } from '../../redesigned/SigninPickPlatform';
import { useNavigate } from 'react-router-dom';
import isAppDomain from 'utils/isAppDomain';

const isMobileApp = isAppDomain();

const SigninPickPlatformScreen = ({ accounts }: { accounts: Account[] }) => {
  const navigate = useNavigate();
  const { openTenant, setScreen, screen } = useAuthLogic();
  const toast = useToast();

  const onFailedSwitch = useCallback(() => {
    toast({
      title: t`Switching to the other organization failed.`,
      description: t`You can still manually visit the other organization`,
      status: 'error',
      position: 'top',
      isClosable: true,
    });
  }, [toast]);

  const onSwitch = useCallback(
    (tenant: Account) => {
      if (isMobileApp) {
        const url = new URL(tenant.auth_url);
        navigate(url.href.replace(url.origin, ''));
        return new Promise<void>(() => {});
      } else {
        return openTenant(tenant).catch(onFailedSwitch);
      }
    },
    [openTenant, onFailedSwitch],
  );

  useLayoutEffect(() => {
    if (accounts.length === 1) {
      const accountAuthUrl = new URL(accounts[0].auth_url);
      if (Capacitor.isNativePlatform()) {
        navigate(accountAuthUrl.href.replace(accountAuthUrl.origin, ''));
      } else {
        // eslint-disable-next-line no-restricted-properties
        window.location.assign(globalizeAuthUrl(accountAuthUrl.href));
      }
    }
  }, [accounts]);

  return <SigninPickPlatform onSwitch={onSwitch} accounts={accounts} />;
};

export default SigninPickPlatformScreen;
